<template>
  <div id="invited-ticket-single-page" class="page">
    <div style="visibility: hidden; z-index: -1; height: 0">
      <input type="text" id="clipboard-store-address" :value="ticket ? ticket.store_address : null" readonly />
    </div>

    <div class="container">
      <div class="page-header">
        <div class="page-header__button-area">
          <!-- -->
        </div>
        <div class="page-header__text-area">
          <template v-if="loaded">
            <transition name="fade" mode="out-in">
              <div :key="0">
                <div class="text-area">
                  <h1 class="text--primary">
                    {{ headerPrimaryText }}
                  </h1>
                  <p class="text--secondary" v-html="headerSecondaryText"></p>
                </div>
              </div>
            </transition>
          </template>
          <template v-else>
            <transition name="fade" mode="out-in">
              <div :key="1" class="text-area">
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="1.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.75rem" />
                </div>
                <div style="padding-top: 1rem">
                  <PuSkeleton :count="1" :loading="true" height="3.5rem" />
                </div>
              </div>
            </transition>
          </template>
        </div>
      </div>
      <div class="page-body">
        <ul class="ticket-message__list">
          <template v-if="loaded">
            <transition-group tag="ul" name="fade" mode="out-in">
              <li class="ticket-message__list-item" v-for="(_component, index) in messageComponents" :key="_component">
                <component
                  :is="_component"
                  :ticket="ticket"
                  :order="order"
                  :class="{ activated: index === messageComponents.length - 1 }"
                  @open-edit="openModal('editModal')"
                  @open-find="openModal('findModal')"
                  @open-invite="openModal('inviteModal')"
                  @open-checkout="
                    $analytics.logEvent('pick up button');
                    openModal('checkOutModal');
                  "
                />
              </li>
            </transition-group>
          </template>
          <template v-else>
            <transition-group name="fade" mode="out-in">
              <div :key="1">
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="1.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.75rem" />
                </div>
                <div style="padding-top: 1rem">
                  <PuSkeleton :count="1" :loading="true" height="3.5rem" />
                </div>
              </div>
              <div :key="2" style="margin-top: 2rem">
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="1.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.75rem" />
                </div>
                <div style="padding-top: 1rem">
                  <PuSkeleton :count="1" :loading="true" height="3.5rem" />
                </div>
              </div>
              <div :key="3" style="margin-top: 2rem">
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="1.5rem" />
                </div>
                <div style="padding: 1rem 0">
                  <PuSkeleton :count="1" :loading="true" height="2.75rem" />
                </div>
                <div style="padding-top: 1rem">
                  <PuSkeleton :count="1" :loading="true" height="3.5rem" />
                </div>
              </div>
            </transition-group>
          </template>
        </ul>
      </div>
      <div class="page-footer">
        <div class="container">
          {{ $__t('C/S') }}
          <a href="tel:+82-218779727"><strong>1877-9727</strong></a>
        </div>

        <div id="check-out-alert-balloon" ref="check-out-alert-balloon" class="page-footer__balloon alert-balloon hidden">
          <div class="content">
            <img src="/img/icon/megaphone.svg" class="content__icon" />
            <span class="content__text">{{$__t('물품 보관 완료 10분 뒤부터 사용가능합니다.')}}</span>
          </div>
        </div>

        <div class="button-area">
          <button
            v-if="ticket && (ticket.status === 'ISSUED' || ticket.status === 'CHECK_IN')"
            type="button"
            class="ui-submit-button activated"
            @click="openQrCodeReader"
          >
            <span class="ui-submit-button__text"> QR코드 스캔 </span>
          </button>
          <button v-else type="button" class="ui-submit-button activated" @click="callCustomerService">
            <span class="ui-submit-button__text"> 고객센터 </span>
          </button>
        </div>
      </div>
    </div>

    <guide-modal id="check-out-modal" ref="checkOutModal" :noCloseButton="true">
      <template v-slot:body>
        <ticket-check-out
          @close-modal="closeModal('checkOutModal')"
          @fetch-ticket="fetchTicket"
          :duration="usageDuration"
          :ticket="ticket"
          :order="order"
          :account="$route.query.phone"
        ></ticket-check-out>
      </template>
    </guide-modal>

    <guide-modal id="find-modal" ref="findModal" :noCloseButton="true" :noBgClick="true" :external-close="true">
      <template v-slot:body>
        <div class="include-modal">
          <div class="include-modal__header">
            <div class="include-modal__header--primary">
              {{ $__t('상점 찾기') }}
            </div>
            <div class="include-modal__header--secondary">
              {{ ticket.status === 'ISSUED' ? $__t('보관하실 상점 위치를 확인해보세요.') : '' }}
            </div>
          </div>
          <div class="include-modal__body">
            <div class="map-button table fixed">
              <ul class="map-button__list table-row">
                <li class="map-button__item table-cell middle">
                  <button v-button type="button" @click="onClickMapAction('google')" class="circle-button">
                    <img src="/img/button/find-google-map.svg" />
                  </button>
                </li>
                <li v-if="$store.state.config.isTouchDevice" class="map-button__item table-cell middle">
                  <button v-button type="button" @click="onClickMapAction('naver')" class="circle-button">
                    <img src="/img/button/find-naver-map.svg" />
                  </button>
                </li>
                <li class="map-button__item table-cell middle text-button">
                  <button
                    type="button"
                    v-button
                    @click="onClickMapAction('copy')"
                    data-clipboard-target="#clipboard-store-address"
                    class="circle-button copy-btn"
                  >
                    <div class="text">{{ $__t('주소복사') }}</div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </guide-modal>
  </div>
</template>

<script>
import MessageIssued from '@/components/includes/TicketSingle/MessageIssued';
import MessageCheckIn from '@/components/includes/TicketSingle/MessageCheckIn';
import MessageCheckOut from '@/components/includes/TicketSingle/MessageCheckOut';
import MessageCancelled from '@/components/includes/TicketSingle/MessageCancelled';
import MessageExpired from '@/components/includes/TicketSingle/MessageExpired';
import MessageInvited from '@/components/includes/TicketSingle/MessageInvited';

import TicketInvite from '@/components/includes/TicketSingle/InviteForm.vue';
import TicketCheckOut from '@/components/includes/TicketSingle/CheckOutForm.vue';

export default {
  name: 'InvitedTicketSingle',

  props: ['code'],

  data() {
    return {
      loaded: false,

      order_id: 0,

      fetchTimer: null,
      usageTimer: null,
      scrollTimer: null,

      usageDuration: 0,

      touchMoving: false,
    };
  },

  computed: {
    ticket() {
      return this.$store.getters['tickets/GET_ITEM']({
        key: 'ticket_code',
        value: this.code
      });
    },

    order() {
      return this.$store.getters['orders/GET_ITEM']({
        key: 'order_id',
        value: this.ticket?.receipt_id
      });
    },

    store() {
      return this.$store.getters['stores/GET_ITEM']({
        key: 'id',
        value: this.ticket?.store_id
      });
    },

    headerPrimaryText() {
      let text = '';

      switch (this.ticket?.status) {
        case 'EXPIRED':
          text = '예약이 만료되었습니다.';
          break;
        case 'ISSUED':
          text = '예약이 완료되었습니다.';
          break;
        case 'CANCELLED':
          text = '예약이 취소되었습니다.';
          break;
        case 'CHECK_IN':
          text = this.headerTicketUsageTimeText;
          break;
        case 'CHECK_OUT':
          text = '이용이 완료되었습니다.';
          break;
      }

      return this.$__t(text);
    },

    headerSecondaryText() {
      let text = '';

      switch (this.ticket?.status) {
        case 'ISSUED':
          text = '물품 보관 시, 호스트에게 QR 티켓을 보여주고 물품을 맡겨주세요.';
          break;
        case 'CHECK_IN':
          text =
            this.$__t('{invite-ticket-single}.header.secondary')
              .replace('{invite_moment}', this.$moment(this.ticket.share.created_at).format('MM월 DD일 HH시 mm분'))
              .replace('{user_name}', this.ticket.user_name) +
            '<br/>' +
            this.$__t('물품 찾을 시, 상점에 도착 후 물품 찾기 버튼을 누르고 물품을 찾아주세요.');
          break;
        case 'CHECK_OUT':
          text = this.usageTimeText;
          break;
      }

      return this.$__t(text);
    },

    headerTicketUsageTimeText() {
      let formula = '00:00:00';

      try {
        if (this.ticket?.datetime_check_in) {
          formula = '';
          let duration_seconds = String(this.usageDuration.seconds());
          let duration_minutes = String(this.usageDuration.minutes());
          let duration_hours = String(this.usageDuration.hours());

          formula = `${duration_seconds.length < 2 ? '0' + duration_seconds : duration_seconds}`;
          formula = `${duration_minutes.length < 2 ? '0' + duration_minutes : duration_minutes}:` + formula;
          formula = `${duration_hours.length < 2 ? '0' + duration_hours : duration_hours}:` + formula;

          if (Math.floor(this.usageDuration.asDays()) != 0) {
            formula = `${Math.floor(this.usageDuration.asDays())}${this.$__t('d')} ` + formula;
          }
        }
      } catch (e) {
      } finally {
        return formula + ' ' + this.$__t('active');
      }
    },

    headerTicketUsagePriceText() {
      let price = 0;

      try {
        if (this.ticket?.datetime_check_in) {
          let total_base_price = this.ticket.ticket_quantity * 2500 + this.ticket.ticket_over_quantity * 3000;
          let total_additional_price = 0;

          let durationMinutes = this.usageDuration.asMinutes();
          let durationDays = this.usageDuration.asDays();

          if (durationMinutes - 60 <= 0) {
            // 1시간 이전
            total_additional_price = 0;
          } else if (0 < durationMinutes - 60 && durationMinutes - 60 <= 390) {
            // 1시간 이후 7시간 30분 이전
            //가격 정책 전
            // total_additional_price =
            //   100 * Math.ceil((durationMinutes - 60) / 10) * this.ticket.ticket_quantity +
            //   150 * Math.ceil((durationMinutes - 60) / 10) * this.ticket.ticket_over_quantity;

            //가격 정책 후
            total_additional_price = 150 * Math.ceil((durationMinutes - 60) / 10) * this.ticket.ticket_quantity;
          } else if (450 < durationMinutes && durationMinutes <= 1440) {
            //7시간 30분 초과 24시간 이하
            //가격 정책 전
            // total_additional_price = 6000 * this.ticket.ticket_quantity + 9000 * this.ticket.ticket_over_quantity - total_base_price;

            //가격 정책 후
            total_additional_price = 8500 * this.ticket.ticket_quantity - total_base_price;
          } else if (1440 < durationMinutes) {
            //24시간 초과
            //가격 정책 전
            // total_additional_price =
            //   (6000 + Math.ceil(durationDays - 1) * 4000) * this.ticket.ticket_quantity +
            //   (9000 + Math.ceil(durationDays - 1) * 6000) * this.ticket.ticket_over_quantity -
            //   total_base_price;

            total_additional_price = (8500 + Math.ceil(durationDays - 1) * 5000) * this.ticket.ticket_quantity - total_base_price;
          }

          price = total_base_price + total_additional_price;

          price = isNaN(price) ? 0 : price.toLocaleString();
        }
      } catch (e) {
      } finally {
        return price;
      }
    },

    usageTimeText() {
      let dateDiff = this.$moment(this.ticket.datetime_check_out).diff(this.$moment(this.ticket.datetime_check_in));

      let minutes = Math.abs(this.$moment.duration(dateDiff).minutes());
      let hours = Math.abs(this.$moment.duration(dateDiff).hours());
      let days = Math.floor(Math.abs(this.$moment.duration(dateDiff).asDays()));

      let value = '';

      if (days > 0) {
        value += ' ' + days + this.$__t('d');
      }

      if (hours > 0) {
        value += ' ' + hours + this.$__t('_h');
      }

      if (minutes > 0) {
        value += ' ' + minutes + this.$__t('m');
      }

      return this.$__t('total') + value + ' ' + this.$__t('use');
    },

    messageComponents() {
      const ALL_MESSAGES = ['MessageExpired', 'MessageIssued', 'MessageCancelled', 'MessageCheckIn', 'MessageInvited', 'MessageCheckOut'];

      let messageList = [];

      if (this.ticket?.status === 'EXPIRED') {
        messageList = ALL_MESSAGES.filter((message_name) => message_name === 'MessageExpired');
      } else if (this.ticket?.status === 'ISSUED') {
        messageList = ALL_MESSAGES.filter((message_name) => message_name === 'MessageIssued');
      } else if (this.ticket?.status === 'CANCELLED') {
        messageList = ALL_MESSAGES.filter((message_name) => message_name === 'MessageCancelled');
      } else if (this.ticket?.status === 'CHECK_IN' || this.ticket?.status === 'CHECK_OUT') {
        messageList = ALL_MESSAGES.filter((message_name) => message_name === 'MessageInvited');
      }

      return messageList;
    }
  },

  watch: {
    ticket: {
      handler(ticket, oldTicket) {
        if (ticket?.status !== oldTicket?.status) {
          this.$store.commit('loading/SET_TRUE');
          setTimeout(() => {
            this.scrollToActivatedTicketMessage();
          }, 0);

          setTimeout(() => {
            this.$store.commit('loading/SET_FALSE');
          }, 1000);
        }

        if (ticket?.status === 'CHECK_IN' && !this.usageTimer) {
          this.setUsageTimer();
        }

        if (oldTicket && ticket?.status !== 'CHECK_IN') {
          clearInterval(this.usageTimer);
        }

        if (ticket?.status === 'CHECK_OUT') {
          this.setUsageDuration();

          clearInterval(this.fetchTimer);
          clearInterval(this.usageTimer);
          clearInterval(this.scrollTimer);
        }
      },
      deep: true
    }
  },

  mounted() {
    window.addEventListener('touchstart', this.setTouchMoving(true));
    window.addEventListener('touchend', this.setTouchMoving(false));

    setTimeout(async () => {
      await this.fetchTicket();
      this.fetchStore();

      this.loaded = true;

      this.$nextTick(() => {
        this.scrollToActivatedTicketMessage();
      });
      setTimeout(() => {
        this.setFetchTimer();

        if (this.ticket?.status === 'CHECK_IN') {
          this.setUsageTimer();
        } else if (this.ticket?.status === 'CHECK_OUT') {
          this.setUsageDuration();
        }
      }, 0);
    }, 1000);
  },

  beforeDestroy() {
    window.removeEventListener('touchstart', this.setTouchMoving(true));
    window.removeEventListener('touchend', this.setTouchMoving(false));
    clearInterval(this.fetchTimer);
    clearInterval(this.usageTimer);
    clearInterval(this.scrollTimer);

    this.$store.commit('resourceForm/UNSET_ITEM', {
      data: { form_id: 'reserve_form' }
    });
  },

  methods: {
    async fetchTicket() {
      try {
        await this.$store.dispatch('tickets/shareAccess', {
          code: this.code,
          account: this.$route.query.phone
        });

        if (this.ticket.status === 'ISSUED') {
          this.$store.commit('resourceForm/SET_ITEM', {
            data: {
              form_id: 'reserve_form',
              date_start: this.ticket.ticket_date_start,
              date_end: this.ticket.ticket_date_end,
              quantity_small: this.ticket.ticket_quantity,
              quantity_big: this.ticket.ticket_over_quantity
            }
          });
        }
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
        this.$log.error(e);
      }
    },

    async fetchStore() {
      let id = this.ticket?.store_id;

      if (id) {
        try {
          return await this.$store.dispatch('stores/get', { id });
        } catch (e) {
          this.$log.error(e);
        }
      }
    },

    openQrCodeReader() {
      setTimeout(() => {
        this.$analytics.logEvent('complete home qr scan');
        this.$store.dispatch('qrCodeScanner/open', {
          type: 'onetime-callback',
          callback: async ({ status, code, store_id, error }) => {
            try {
              if (status === 'ERROR') {
                throw error;
              }

              if (status === 'SUCCESS') {
                this.$router.push({
                  name: 'Stores',
                  query: { autoSearch: 'target', sid: store_id }
                });
              }
            } catch (e) {
              this.$store.commit('alert/ADD_ITEM', {
                message: e.toString(),
                status: 'error'
              });
            }
          }
        });
      }, 300);
    },

    // async fetchOrder() {
    //   let id = this.ticket?.receipt_id;

    //   if (id) {
    //     try {
    //       return await this.$store.dispatch("orders/order", { id });
    //     } catch (e) {
    //       this.$log.error(e);
    //     }
    //   }
    // },

    // async fetchInvitation() {
    //   try {
    //     let res = await this.$store.dispatch("tickets/getShare", { code: this.code });

    //     const INVITATION = res?.data?.data?.items;

    //     if (INVITATION) {
    //       this.invitation = INVITATION;
    //     }
    //   } catch (e) {
    //     this.$log.error(e);
    //   }
    // },

    setFetchTimer() {
      this.fetchTimer = setInterval(async () => {
        await this.fetchTicket();
      }, 6000);
    },

    setUsageTimer() {
      this.usageTimer = setInterval(() => {
        setTimeout(() => {
          this.setUsageDuration();
        }, 0);
      }, 1000);
    },

    setUsageDuration() {
      const MOMENT_CHECK_IN = this.$moment(this.ticket?.datetime_check_in);
      const MOMENT_POINT = this.$moment(this.ticket?.datetime_check_out);

      this.usageDuration = this.$moment.duration(Math.abs(MOMENT_CHECK_IN.diff(MOMENT_POINT)));
    },

    scrollToActivatedTicketMessage() {
      return this.$nextTick(() => {
        const activatedList = this.$el.querySelectorAll('.ticket-message.activated');
        const header = this.$refs.header;

        if (activatedList.length > 0) {
          const elOffset = $(activatedList[activatedList.length - 1]).offset().top;
          const headerHeight = header?.offsetHeight || 188;

          let offset = elOffset - headerHeight;

          $('html, body').animate({ scrollTop: offset }, 200);
        }
      });
    },

    onClickMapAction(method) {
      if (method === 'copy') {
        this.$copyText(this.ticket.store_address).then((e) => {
          this.$store.commit('notice/ADD_ITEM', {
            message: this.$__t('복사되었습니다.')
          });
        }, {});
      } else {
        switch (method) {
          case 'naver':
            if (this.wni.isNative) {
              this.wni.execute('wnOpenURL', {
                url: `nmap://place?lat=${this.store.geo_latitude}&lng=${this.store.geo_longitude}&zoom=10&name=${this.store.store_name}&appname=${this.$store.state.config.siteURL}`
              });
            } else {
              window.open(
                `nmap://place?lat=${this.store.geo_latitude}&lng=${this.store.geo_longitude}&zoom=10&name=${this.store.store_name}&appname=${this.$store.state.config.siteURL}`,
                '_blank'
              );
            }
            break;

          case 'google':
            if (this.wni.isNative) {
              this.wni.execute('wnOpenURL', {
                url: `https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`
              });
            } else {
              window.open(`https://www.google.com/maps/search/?api=1&query=${this.store.geo_latitude},${this.store.geo_longitude}`, '_blank');
            }
            break;
        }
      }
    },

    reload() {
      this.$refs.reload.classList.add('rotate');
      this.$store.commit('loading/SET_TRUE');

      setTimeout(() => {
        this.$store.commit('loading/SET_FALSE');
        this.$refs.reload.classList.remove('rotate');
      }, 1000);
    },

    setTouchMoving(moving = false) {
      return () => {
        this.touchMoving = moving;
      };
    },

    openModal(ref) {
      this.$refs[ref].open();
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },

    onBack() {
      return this.$router.back();
    },

    openQrCodeScanner() {
      if (this.ticket.status === 'CHECK_IN' && !this.checkCheckOutAvailable()) {
        return this.handleCheckOutAlertBalloon();
      }

      this.$store.dispatch('qrCodeScanner/open', {
        type: 'onetime-callback',
        callback: async ({ status, code, store_id, error }) => {
          try {
            if (status === 'ERROR') {
              throw error;
            }

            if (status === 'SUCCESS') {
              setTimeout(() => {
                this.$router.push({ name: 'Redirect', params: { code: code } });
              }, 0);
            }
          } catch (e) {
            this.$store.commit('alert/ADD_ITEM', {
              message: e.toString(),
              status: 'error'
            });
          }
        }
      });
    },

    handleCheckOutAlertBalloon() {
      const $checkOutAlertBalloon = this.$refs['check-out-alert-balloon'];
      $checkOutAlertBalloon.classList.remove('hidden');

      if (this._checkOutAlertBalloon) {
        this._checkOutAlertBalloon = null;
        clearTimeout(this._checkOutAlertBalloon);
      }

      this._checkOutAlertBalloon = setTimeout(() => {
        $checkOutAlertBalloon.classList.add('hidden');
      }, 2000);
    }
  },

  components: {
    MessageIssued,
    MessageCheckIn,
    MessageCheckOut,
    MessageCancelled,
    MessageExpired,
    MessageInvited,

    TicketEdit: () => import('@/components/pages/store/StoresSearchCondition'),
    TicketInvite,
    TicketCheckOut
  }
};
</script>

<style lang="scss" scoped>
#invite-modal::v-deep.lug-guide-modal,
#check-out-modal::v-deep.lug-guide-modal {
  & > .lug-guide-modal-container {
    width: 90%;
    max-width: $max-content-width;

    & > .body {
      padding: 0;
    }
  }
}

#find-modal::v-deep.lug-guide-modal {
  & > .lug-guide-modal-container {
    width: 90%;
    max-width: 320px;
  }
}

.page-footer {
  .button-area {
    width: 100%;
    padding: unit(16);
  }

  &__balloon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: auto;
    min-width: unit(280);
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16)) drop-shadow(4px 8px 28px rgba(0, 0, 0, 0.08));
    transition: all 0.2s;
    opacity: 1;

    .hidden {
      opacity: 0;
      transition: all 0.2s;
    }
  }
}
</style>
